/* eslint-disable import/no-extraneous-dependencies */
import Modal from "@fdn/modal";
import React from "react";

const desktop = ({ ...props }) => (
	<div className="init-modal-wrapper">
		<Modal
			is_mobile={props.isMobile}
			show={props.isShow}
			background_image="null"
			closable
			width="316px"
			on_click={props.onClick}
		>
			<div className="init-modal-content">
				<div className="logo-wrapper">
					<img className="icon-point" alt="Give Point" src="https://image.femaledaily.com/web-assets/point_box.png" />
				</div>
				<div className="allo-info-wrapper">
					<p className="dec">
						Exciting features and products from
						<br />
						CT Corp are coming your way!
						<br />
						Stay tuned!
					</p>
				</div>
				<div className="btn-wrapper">
					<button type="button" className="btn-allo-cta" onClick={() => props.onClick()}>I got it!</button>
				</div>
			</div>
		</Modal>
		<style>
			{`
      .init-modal-wrapper {
        position: fixed;
        top: 0;
        left: 0;
        z-index: 3;
				max-width: 316px;
      }

      .init-modal-wrapper .fdn-modal {
				max-width: 266px;
				max-height: 266px;
        border-radius: 16px;
				padding: 24px;
				-webkit-animation: none !important;
    		animation: none !important;
      }

      .init-modal-wrapper .fdn-modal .fdn-modal-header span{
        color: #AFAFAF;
				font-weight: normal;
      }

      .init-modal-wrapper .init-modal-content {
        display: flex;
				flex-direction: column;
				align-items: center;
				text-align: center;
				gap: 0.5rem;
      }

      .init-modal-wrapper .init-modal-content .logo-wrapper .icon-point {
        width: 143.53px;
        hight: 126.84px;
      }   

			.init-modal-wrapper .init-modal-content .allo-info-wrapper .dec{
        font-family: Poppins;
				font-style: normal;
				font-weight: 600;
				font-size: 14px;
				line-height: 17px;
				color: #333333;
				padding: 14px 0;
      }   

			.btn-wrapper .btn-allo-cta{
				padding: 0.5rem 3.5rem;
				border-radius: 24px;
				background-color: #FFBC25;
				border: 1px gray;
        font-family: Poppins;
				font-weight: 600;
				font-size: 12px;
				line-height: 14.52px;
				cursor: pointer;
				color: #333333;
			}
      `}
		</style>
	</div>
);

export default desktop;
